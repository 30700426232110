import React from 'react'
import SEO from '../common/SEO'
import HeaderKorlo from '../common/header/HeaderKorlo'
import CopyrightKorlo from '../common/footer/CopyrightKorlo'
import KorloSectionTitle from '../elements/sectionTitle/KorloSectionTitle'
import KorloTeam from '../elements/team/KorloTeam'

const KorloBusinessPage = () => {
  return (
    <>
      <SEO title='Korlo AI' />
      <main className='page-wrapper'>
        <HeaderKorlo btnStyle='btn-small' />

        <div className='rwt-team-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <KorloSectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Our Experts.'
                  title='Our Experts'
                  description="At Korlo AI, we're bringing together talent from around the world dedicated to improving AI language preservation and online education."
                />
              </div>
            </div>
            <KorloTeam
              column='col-lg-4 col-md-6 col-12 mt--30'
              teamStyle='team-style-default'
            />
          </div>
        </div>

        {/* <CopyrightKorlo /> */}
      </main>
    </>
  )
}
export default KorloBusinessPage
