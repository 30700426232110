import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  getDictionaryDetails,
  updateDictionary,
} from '../actions/dictionaryActions'
// import { UPDATE_QUESTION_DETAILS_RESET } from '../constants/dictionaryConstants'
import {
  dropdown_flag_options,
  dropdown_level_options,
  dropdown_question_type_options,
  dropdown_target_options,
  dropdown_user_options,
  dropdown_weight_options,
} from '../constants/dropdownConstants'
import classes from './EditScreen.module.css'
import { UPDATE_DICTIONARY_DETAILS_RESET } from '../constants/dictionaryConstants'

const DictionaryEditScreen = () => {
  const params = useParams()
  const entryId = params.id
  let history = useNavigate()

  const [text, setText] = useState('')
  const [definition, setDefinition] = useState('')
  const [level, setLevel] = useState('')
  const [sentence, setSentence] = useState('')
  const [wordSpanish, setWordSpanish] = useState('')
  const [sentenceSpanish, setSentenceSpanish] = useState('')

  //   const updateAnswers = (index, e) => {
  //     let newArr = [...answers] // copying the old datas array
  //     if (e.target.id.includes('answer')) {
  //       newArr[index]['text'] = e.target.value // replace e.target.value with whatever you want to change it to
  //       setAnswers(newArr)
  //     } else if (e.target.id.includes('isCorrect')) {
  //       newArr[index]['isCorrect'] = e.target.value
  //       setAnswers(newArr)
  //     } else {
  //     }
  //   }

  //   const addOptionHandler = (e) => {
  //     e.preventDefault()
  //     let newArr = [...answers]
  //     newArr.push({ isCorrect: 'false', text: 'Answer' })
  //     setAnswers(newArr)
  //   }

  //   const removeOptionHandler = (e) => {
  //     e.preventDefault()
  //     let newArr = [...answers]
  //     newArr.pop()
  //     setAnswers(newArr)
  //   }

  const dispatch = useDispatch()

  const dictionaryDetails = useSelector((state) => state.dictionaryDetails)
  const { loading, error } = dictionaryDetails
  const dictionaryFetched = dictionaryDetails.entry

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const dictionaryUpdate = useSelector((state) => state.dictionaryUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = dictionaryUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: UPDATE_DICTIONARY_DETAILS_RESET })
      history('/admin/dictionary')
    } else {
      if (!dictionaryFetched._id || dictionaryFetched._id !== entryId) {
        dispatch(getDictionaryDetails(entryId))
      } else {
        setText(dictionaryFetched.text)
        setLevel(dictionaryFetched.level)
        setDefinition(dictionaryFetched.definition)
        setSentence(dictionaryFetched.sentence)
        setSentenceSpanish(dictionaryFetched.sentence_spanish)
        setWordSpanish(dictionaryFetched.word_spanish)
      }
    }

    console.log(
      dropdown_level_options.filter((option) => option.value === level)
    )
  }, [dispatch, history, entryId, dictionaryFetched])

  // Dynamic Dropdowns

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateDictionary({
        _id: entryId,
        text: text,
        level: level,
        definition: definition,
        sentence: sentence,
        word_spanish: wordSpanish,
        sentence_spanish: sentenceSpanish,
      })
    )
  }

  return (
    <div className={classes.container}>
      <Link to='/dictionary' className='btn btn-light my-3'>
        Go Back
      </Link>
      <div className={classes.formContainer}>
        <>
          <h1>Edit Question</h1>
          {loadingUpdate && <Loader />}
          {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='question'>
                <Form.Label>Text</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter question text'
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='category'>
                <Form.Label>Definition</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter question category'
                  value={definition}
                  onChange={(e) => setDefinition(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='level'>
                <Form.Label>Level</Form.Label>
                <Form.Control
                  as='select'
                  className={classes.dropdownContainer}
                  value={level}
                  // dropdown_level_options.filter(
                  //   (option) => option['value'] === level
                  // )[0].value

                  onChange={(e) => setLevel(e.target.value)}
                >
                  {dropdown_level_options.slice(1).map((option) => (
                    <option href='#' value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId='full_question'>
                <Form.Label>Sentence</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter full question'
                  value={sentence}
                  onChange={(e) => setSentence(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='text_type'>
                <Form.Label>Word Spanish</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter text type'
                  value={wordSpanish}
                  onChange={(e) => setWordSpanish(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='citation'>
                <Form.Label>Sentence Spanish</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter citation if necessary'
                  value={sentenceSpanish}
                  onChange={(e) => setSentenceSpanish(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Button
                className={classes.updateQuestionButton}
                type='submit'
                variant='primary'
              >
                Update
              </Button>
            </Form>
          )}
        </>
      </div>
    </div>
  )
}

export default DictionaryEditScreen
