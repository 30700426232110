import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
// import { getUserDetails, updateUserProfile } from '../actions/userActions'
// import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
// import {
//   deleteFollower,
//   listMyFollowedClassrooms,
// } from '../actions/followerActions'
import classes from './ProfileScreen.module.css'

const ProfileScreen = () => {
  let history = useNavigate()
  let location = useLocation()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  // const orderListMy = useSelector((state) => state.orderListMy)
  // const { loading: loadingOrders, error: errorOrders, orders } = orderListMy

  const followerGetClassrooms = useSelector(
    (state) => state.followerGetClassrooms
  )
  const {
    loading: loadingFollowerGetClassrooms,
    error: errorFollowerGetClassrooms,
    classrooms,
  } = followerGetClassrooms

  const followerDelete = useSelector((state) => state.followerDelete)
  const {
    loading: loadingFollowerDelete,
    error: errorFollowerDelete,
    success: followerDeleteSuccess,
  } = followerDelete

  // useEffect(() => {
  //   if (!userInfo) {
  //     history('/login')
  //   } else {
  //     if (!user || !user.name || success) {
  //       dispatch({ type: USER_UPDATE_PROFILE_RESET })
  //       dispatch(getUserDetails('profile'))
  //       dispatch(listMyFollowedClassrooms())
  //     } else {
  //       setName(user.name)
  //       setEmail(user.email)
  //     }
  //   }
  // }, [dispatch, history, userInfo, followerDeleteSuccess, user, success])

  // useEffect(() => {
  //   dispatch(listMyFollowedClassrooms())
  // }, [followerDeleteSuccess])

  // const submitHandler = (e) => {
  //   e.preventDefault()
  //   if (password !== confirmPassword) {
  //     setMessage('Passwords do not match')
  //   } else {
  //     dispatch(updateUserProfile({ id: user._id, name, email, password }))
  //   }
  // }

  // const deleteHandler = (id) => {
  //   if (window.confirm('Are you sure you want to leave this classroom?')) {
  //     dispatch(deleteFollower(id))
  //   }
  // }

  // const clickHandler = () => {}

  return (
    <div className={classes.container}>
      <Row>
        <Col md={5}>
          <h2>User Profile</h2>
          {message && <Message variant='danger'>{message}</Message>}
          {}
          {success && <Message variant='success'>Profile Updated</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Form>
              {/* <Form onSubmit={submitHandler}></Form> */}
              <Form.Group controlId='name'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='name'
                  placeholder='Enter name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='email'>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='password'>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Enter password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='confirmPassword'>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Confirm password'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Button type='submit' variant='primary'>
                {/* <Button type='submit' variant='primary' onClick={clickHandler()}> */}
                Update
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default ProfileScreen
