import axios from 'axios'
import {
  DICTIONARY_GET_ALL_FAIL,
  DICTIONARY_GET_ALL_REQUEST,
  DICTIONARY_GET_ALL_SUCCESS,
  GET_DICTIONARY_DETAILS_FAIL,
  GET_DICTIONARY_DETAILS_REQUEST,
  GET_DICTIONARY_DETAILS_SUCCESS,
  UPDATE_DICTIONARY_DETAILS_FAIL,
  UPDATE_DICTIONARY_DETAILS_REQUEST,
  UPDATE_DICTIONARY_DETAILS_SUCCESS,
} from '../constants/dictionaryConstants'

import { logout } from './userActions'

import apiConstant from '../constants/apiConstant'

const API =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_PRODUCTION
    : process.env.REACT_APP_API_DEVELOPMENT

export const filterDictionary =
  (filter, keyword = '', pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      dispatch({ type: DICTIONARY_GET_ALL_REQUEST })

      const { data } = await axios.get(
        `${API}/api/dictionary/filter/?keyword=${keyword}&pageNumber=${pageNumber}`,
        {
          params: filter,
        },

        config
      )

      dispatch({
        type: DICTIONARY_GET_ALL_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DICTIONARY_GET_ALL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getDictionaryDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_DICTIONARY_DETAILS_REQUEST })

    const { data } = await axios.get(`${API}/api/dictionary/${id}`)

    dispatch({
      type: GET_DICTIONARY_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_DICTIONARY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateDictionary = (entry) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_DICTIONARY_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${API}/api/dictionary/${entry._id}`,
      entry,
      config
    )

    dispatch({ type: GET_DICTIONARY_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: UPDATE_DICTIONARY_DETAILS_FAIL,
      payload: message,
    })
  }
}
