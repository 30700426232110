import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

const TimelineData = [
  {
    id: '1',
    title: 'AI',
    description: 'Custom LLMs generate language-learning content',
  },
  {
    id: '2',
    title: 'Data Scientists',
    description:
      'The content is evaluated by both data scientists and experienced teachers',
  },
  {
    id: '3',
    title: 'UX Designers',
    description:
      'The results are fashioned into beautiful user interfaces by our team of designers',
  },
]

const TimelineTwo = ({ classVar }) => {
  return (
    <div className='timeline-style-two bg-color-blackest'>
      <div className='row row--0'>
        {/* Start Single Progress */}
        {TimelineData.map((data, index) => (
          <div
            className={`col-lg-4 col-md-4 rn-timeline-single ${classVar}`}
            key={index}
          >
            <div className='rn-timeline'>
              <ScrollAnimation
                animateIn='fadeInUp'
                animateOut='fadeInOut'
                animateOnce={true}
              >
                <h6 className='title'>{data.title}</h6>
              </ScrollAnimation>
              <div className='progress-line'>
                <div className='line-inner'></div>
              </div>
              <div className='progress-dot'>
                <div className='dot-level'>
                  <div className='dot-inner'></div>
                </div>
              </div>
              <ScrollAnimation
                animateIn='fadeInUp'
                animateOut='fadeInOut'
                animateOnce={true}
              >
                <p className='description'>{data.description}</p>
              </ScrollAnimation>
            </div>
          </div>
        ))}
        {/* End Single Progress */}
      </div>
    </div>
  )
}
export default TimelineTwo
