import React from 'react'
import SEO from '../common/SEO'
import HeaderKorlo from '../common/header/HeaderKorlo'
import CopyrightKorlo from '../common/footer/CopyrightKorlo'
import Slider from 'react-slick'
import { BannerActivation } from '../utils/script'
import Separator from '../elements/separator/Separator'
import SectionTitle from '../elements/sectionTitle/SectionTitle'
import TestimonialOne from '../elements/testimonial/TestimonialOne'
import BlogClassicData from '../data/blog/BlogList.json'
import RnAccordion from '../elements/accordion/RnAccordion'
import AdvancePricingOne from '../elements/advancePricing/AdvancePricingOne'
import TimelineTwo from '../elements/timeline/TimelineTwo'
import Team from '../elements/team/Team'

const KorloBusinessPage = () => {
  return (
    <>
      <SEO title='Korlo AI' />
      <main className='page-wrapper'>
        <HeaderKorlo btnStyle='btn-small' />

        {/* Start Slider Area  */}

        {/* End Slider Area  */}

        {/* Start Service Area  */}
        {/* <div className='rn-service-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='State of the Art AI Tech'
                  title=''
                  description='We harness the power of AI to optimize learning outcomes.'
                />
              </div>
            </div>
            <ServiceFive serviceStyle='gallery-style' textAlign='text-start' />
          </div>
        </div> */}
        {/* End Service Area  */}

        {/* <Separator />
        <div className='rwt-portfolio-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='language learning for serious people'
                  title='Korlo AI'
                  description=''
                />
              </div>
            </div> */}
        {/* <PortfolioOne Column='col-lg-6 col-md-6 col-sm-6 col-12 mt--30 portfolio no-overlay' /> */}
        {/* </div>
        </div> */}

        <Separator />

        <div className='rwt-progressbar-area rn-section-gap'>
          <div className='container'>
            <div className='row mb--25'>
              <div className='col-lg-4 offset-lg-1'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Bringing AI to Language Learning'
                  title='A Personalized Learning Experience'
                  description='Our learning engine is designed for an AI future with students, teachers, and professionals in mind.'
                />
              </div>
              <div className='col-lg-4 offset-lg-1'></div>

              {/* <div className='col-lg-10 offset-lg-1'>
              <CircleProgress />
            </div> */}
            </div>
          </div>
        </div>

        <div className='rwt-progressbar-area rn-section-gap'>
          <div className='container'>
            <div className='row mb--25 mt-25'>
              <div className='col-lg-10 offset-lg-1'></div>
            </div>
            <RnAccordion />

            {/* <div className='col-lg-10 offset-lg-1'>
              <CircleProgress />
            </div> */}
          </div>
        </div>
        <div className='rwt-progressbar-area rn-section-gap'>
          <div className='container'>
            <div className='row mb--25 mt-25'>
              <div className='col-lg-10 offset-lg-1'></div>
            </div>
            <AdvancePricingOne />

            {/* <div className='col-lg-10 offset-lg-1'>
              <CircleProgress />
            </div> */}
          </div>
        </div>
        <div className='rwt-progressbar-area rn-section-gap'>
          <div className='container'>
            <div className='row mb--25 mt-25'>
              <div className='col-lg-10 offset-lg-1'></div>
            </div>
            <TimelineTwo />

            {/* <div className='col-lg-10 offset-lg-1'>
              <CircleProgress />
            </div> */}
          </div>
        </div>
        <div className='rwt-progressbar-area rn-section-gap'>
          <div className='container'>
            <div className='row mb--25 mt-25'>
              <div className='col-lg-10 offset-lg-1'></div>
            </div>
            <Team />

            {/* <div className='col-lg-10 offset-lg-1'>
              <CircleProgress />
            </div> */}
          </div>
        </div>
        {/* 
        <Separator />
        <div className='blog-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Get The Latest News'
                  title='What Our Trending News.'
                  description='We provide company and finance service for <br /> startups and company business.'
                />
              </div>
            </div>
            <div className='row row--15'>
              {BlogListData.map((item) => (
                <div key={item.id} className='col-lg-4 col-md-6 col-12 mt--30'>
                  <BlogList StyleVar='box-card-style-default' data={item} />
                </div>
              ))}
            </div>
          </div>
        </div> */}

        <Separator />
        {/* Start Testimonial Area  */}
        <div className='rwt-testimonial-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                {/* <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Client Feedback'
                  title='What People Are Saying.'
                  description='There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.'
                /> */}
              </div>
            </div>
            <TestimonialOne
              column='col-lg-4 col-md-6 col-sm-6 col-12 mt--30'
              teamStyle='card-style-default testimonial-style-one style-two'
            />
          </div>
        </div>
        {/* End Testimonial Area  */}
        <CopyrightKorlo />
      </main>
    </>
  )
}
export default KorloBusinessPage
