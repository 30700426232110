import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import PageScrollTop from './components/pageToTop/PageScrollTop'

// My Pages

import KorloLandingPage from './pages/KorloLandingPage'
import KorloContactPage from './pages/KorloContactPage'
import KorloLanguagesPage from './pages/KorloLanguagesPage'
import KorloProductPage from './pages/KorloProductPage'
import KorloAboutPage from './pages/KorloAboutPage'
import KorloTeamPage from './pages/KorloTeamPage'
// Elements import Here

// Import Css
import './assets/scss/style.scss'
import LoginScreen from './pagesForms/LoginScreen'
import RegisterScreen from './pagesForms/RegisterScreen'
import ProfileScreen from './pagesForms/ProfileScreen'
import DictionaryListScreen from './pagesDictionary/DictionaryListScreen'
import DictionaryEditScreen from './pagesEdit/DictionaryEditScreen'

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Routes>
          <Route
            path={`${process.env.PUBLIC_URL + '/'}`}
            exact
            element={<KorloLandingPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/about'}`}
            exact
            element={<KorloAboutPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/product'}`}
            exact
            element={<KorloProductPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/team'}`}
            exact
            element={<KorloTeamPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/languages'}`}
            exact
            element={<KorloLanguagesPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/contact'}`}
            exact
            element={<KorloContactPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/korlo'}`}
            exact
            element={<KorloLandingPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/korlo'}`}
            exact
            element={<KorloLandingPage />}
          />

          {/* Form Screens */}
          <Route
            path={`${process.env.PUBLIC_URL + '/login'}`}
            exact
            element={<LoginScreen />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/register'}`}
            exact
            element={<RegisterScreen />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/profile'}`}
            exact
            element={<ProfileScreen />}
          />

          <Route
            path={`${process.env.PUBLIC_URL + '/dictionary'}`}
            exact
            element={<DictionaryListScreen />}
          />
          <Route
            path='/admin/dictionary'
            element={<DictionaryListScreen />}
            exact={true}
          />
          <Route
            path='/admin/dictionary/page/:pageNumber'
            element={<DictionaryListScreen />}
            exact={true}
          />
          <Route
            path='/admin/dictionary/search/:keyword/page/:pageNumber'
            element={<DictionaryListScreen />}
            exact={true}
          />
          <Route
            path='/admin/dictionary/search/:keyword'
            element={<DictionaryListScreen />}
            exact={true}
          />
          <Route
            path='/admin/dictionary/:id/edit'
            element={<DictionaryEditScreen />}
          />
        </Routes>
      </PageScrollTop>
    </Router>
  )
}

export default App
