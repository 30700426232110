export const dropdown_user_options = [
  { value: '', label: 'ALL LANGUAGES' },
  { value: 'english', label: 'English' },
]

export const dropdown_target_options = [
  { value: '', label: 'ALL LANGUAGES' },
  { value: 'english', label: 'English' },
  { value: 'spanish', label: 'Spanish' },
  { value: 'french', label: 'French' },
  { value: 'italian', label: 'Italian' },
  { value: 'portuguese', label: 'Portuguese' },
  { value: 'german', label: 'German' },
  { value: 'dutch', label: 'Dutch' },
  { value: 'dzongkha', label: 'Dzongkha' },
  { value: 'japanese', label: 'Japanese' },
  { value: 'other', label: 'Other' },
]

export const dropdown_level_options = [
  { value: '', label: 'ALL LEVELS' },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
]

export const dropdown_pos_options = [
  { value: '', label: 'ALL PARTS OF SPEECH' },
  { value: 'ADJ', label: 'ADJ' },
  { value: 'ADP', label: 'ADP' },
  { value: 'ADV', label: 'ADV' },
  { value: 'AUX', label: 'AUX' },
  { value: 'CCONJ', label: 'CCONJ' },
  { value: 'DET', label: 'DET' },
  { value: 'INTJ', label: 'INTJ' },
  { value: 'NOUN', label: 'NOUN' },
  { value: 'NUM', label: 'NUM' },
  { value: 'PART', label: 'PART' },
  { value: 'PRON', label: 'PRON' },
  { value: 'PROPN', label: 'PROPN' },
  { value: 'PUNCT', label: 'PUNCT' },
  { value: 'SCONJ', label: 'SCONJ' },
  { value: 'SPACE', label: 'SPACE' },
  { value: 'SYM', label: 'SYM' },
  { value: 'VERB', label: 'VERB' },
  { value: 'X', label: 'X' },
]

export const dropdown_question_type_options = [
  { value: '', label: 'ALL QUESTION TYPES' },
  { value: 'multiple_choice', label: 'Multiple Choice' },
  { value: 'fill_in_the_blank', label: 'Fill in the Blank' },
  { value: 'translation', label: 'Translation' },
  { value: 'conjugation', label: 'Conjugation' },
]

export const dropdown_text_type_options = [
  { value: '', label: 'ALL TEXT TYPES' },
  { value: 'ai', label: 'AI' },
  { value: 'gutenburg', label: 'Gutenburg' },
]

export const dropdown_flag_options = [
  { value: '', label: 'ALL FLAG TYPES' },
  { value: true, label: 'FLAGGED!' },
  { value: false, label: 'Unflagged' },
  { value: 'save', label: 'Saved for Later' },
]

export const dropdown_boolean_options = [
  { value: '', label: '' },
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
]
