import React from 'react'
import SEO from '../common/SEO'
import { Link } from 'react-router-dom'
import HeaderKorlo from '../common/header/HeaderKorlo'
import CopyrightKorlo from '../common/footer/CopyrightKorlo'
import Separator from '../elements/separator/Separator'
import BlogClassicData from '../data/blog/BlogList.json'
import AccordionOne from '../elements/accordion/AccordionOne'

const KorloAboutPage = () => {
  return (
    <>
      <SEO title='About' />
      <main className='page-wrapper'>
        <HeaderKorlo btnStyle='btn-small' />

        <Separator />

        <div className='rn-accordion-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-10 offset-lg-1'></div>
            </div>
            <div className='row mt--35 row--20'>
              <div className='col-lg-10 offset-lg-1'>
                <AccordionOne customStyle='' />
              </div>
            </div>
          </div>
        </div>
        <div className='rn-accordion-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-10 offset-lg-1'></div>
            </div>
            <div className='row mt--35 row--20'>
              <div className='col-lg-10 offset-lg-1'></div>
            </div>
          </div>
        </div>
        {/* End Accordion Area  */}
      </main>
      <CopyrightKorlo />
    </>
  )
}
export default KorloAboutPage
