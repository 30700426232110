import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import classes from './Korlo.module.css'

const LogoKorlo = ({ image, image2 }) => {
  return (
    <div className='logo'>
      <Link to={process.env.PUBLIC_URL + '/'}>
        <img
          className='logo-light'
          src={process.env.PUBLIC_URL + image}
          alt='Corporate Logo'
        />
        <img
          className='logo-dark'
          src={process.env.PUBLIC_URL + image2}
          alt='Corporate Logo'
        />
      </Link>
    </div>
  )
}
LogoKorlo.propTypes = {
  image: PropTypes.string,
}

export default LogoKorlo
