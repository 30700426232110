import React from 'react'
import SEO from '../common/SEO'
import HeaderKorlo from '../common/header/HeaderKorlo'
import CopyrightKorlo from '../common/footer/CopyrightKorlo'
import Separator from '../elements/separator/Separator'
import BlogClassicData from '../data/blog/BlogList.json'
import TimelineTwo from '../elements/timeline/TimelineTwo'
import KorloSectionTitle from '../elements/sectionTitle/KorloSectionTitle'
import TimeNetwork from '../dashboard/TimeNetwork'
import timedata from '../data/dashboard/sfhh@4.json'

const KorloLandingPage = () => {
  return (
    <>
      <SEO title='Korlo AI' />
      <main className='page-wrapper'>
        <HeaderKorlo btnStyle='btn-small' />

        <Separator />

        <div className='rwt-progressbar-area rn-section-gap'>
          <div className='container'>
            <div className='row mb--25'>
              <div className='col-lg-4 offset-lg-1'>
                <KorloSectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Bringing AI to Language Learning'
                  title='The Personalized Polyglot AI Experience'
                  description='Our new language learning platform is being engineered for an AI future with students, teachers, and professionals in mind.'
                />
              </div>
              <div className='col-lg-4 offset-lg-1'>
                <TimeNetwork
                  height={1000}
                  width={1000}
                  data={timedata}
                  colorOne={'white'}
                  colorTwo={'black'}
                  radius={4}
                  timeStep={300}
                />
              </div>
              <div className='col-lg-4 offset-lg-1'></div>

              {/* <div className='col-lg-10 offset-lg-1'>
              <CircleProgress />
            </div> */}
            </div>
          </div>
        </div>

        {/* 
        <Separator />
        <div className='blog-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Get The Latest News'
                  title='What Our Trending News.'
                  description='We provide company and finance service for <br /> startups and company Landing.'
                />
              </div>
            </div>
            <div className='row row--15'>
              {BlogListData.map((item) => (
                <div key={item.id} className='col-lg-4 col-md-6 col-12 mt--30'>
                  <BlogList StyleVar='box-card-style-default' data={item} />
                </div>
              ))}
            </div>
          </div>
        </div> */}

        <Separator />

        {/* <CopyrightKorlo /> */}
      </main>
    </>
  )
}
export default KorloLandingPage
