import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Form, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import { filterDictionary } from '../actions/dictionaryActions'
// import { QUESTION_CREATE_RESET } from '../constants/questionConstants'
import SearchBox from '../components/SearchBox'
import {
  dropdown_level_options,
  dropdown_pos_options,
} from '../constants/dropdownConstants'
import classes from './ListScreen.module.css'

const DictionaryListScreen = () => {
  const params = useParams()
  const pageNumber = params.pageNumber || 1

  const { keyword } = useParams()

  // const [level, setLevel] = useState('')
  // const [pos_, setPos_] = useState('')

  const [filter, setFilter] = useState({
    level: '',
    pos_: '',
  })

  const [userLanguage, setUserLanguage] = useState('')
  const [targetLanguage, setTargetLanguage] = useState('')
  const [textType, setTextType] = useState('')
  const [questionType, setQuestionType] = useState('')
  const [showFilters, setShowFilters] = useState(false)

  let history = useNavigate()

  const dispatch = useDispatch()

  const dictionaryFilter = useSelector((state) => state.dictionaryFilter)
  const { loading, error, entries, page, pages } = dictionaryFilter

  function filterChangeHandler(inputIdentifier, enteredValue) {
    setFilter((curFilter) => {
      return {
        ...curFilter,
        [inputIdentifier]: enteredValue,
      }
    })
  }
  // const filter = {
  //   level: level,
  //   pos_: pos_,
  // }

  // const dropdown_level_options = [
  //   { value: '', label: 'ALL LEVELS' },
  //   { value: 1, label: 1 },
  //   { value: 2, label: 2 },
  //   { value: 3, label: 3 },
  //   { value: 4, label: 4 },
  //   { value: 5, label: 5 },
  //   { value: 6, label: 6 },
  // ]

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  console.log(userInfo)

  // Use Effects

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history('/login')
    } else {
      dispatch(filterDictionary(filter, keyword, pageNumber))
    }
  }, [dispatch, history, userInfo, pageNumber, params, filter])

  // useEffect(() => {
  //   if (!userInfo || !userInfo.isAdmin) {
  //     history('/login')
  //   } else {
  //     dispatch(filterDictionary(filter, '', pageNumber))
  //   }
  // }, [level])

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history('/login')
    } else {
      history(
        keyword
          ? `/admin/dictionary/search/${keyword}/page/${1}`
          : `/admin/dictionary/page/${1}`
      )
    }
  }, [filter])

  //   const deleteHandler = (id) => {
  //     if (window.confirm('Are you sure')) {
  //       dispatch(deleteQuestion(id))
  //     }
  //   }
  //   const createQuestionHandler = () => {
  //     dispatch(createQuestion())
  //   }

  const filterHandler = () => {
    setShowFilters(!showFilters)
  }

  return (
    <div className={classes.container}>
      <Row className='align-items-center'>
        <Col>
          <h1>Dictionary</h1>
        </Col>
        <Col className='text-right'>
          {/* <Button
            className={classes.createQuestionButton}
            // onClick={createQuestionHandler}
          >
            <i className='fas fa-plus'></i> Create New Question
          </Button> */}
        </Col>
      </Row>
      <div className={classes.filterContainer}>
        <Button className={classes.showFiltersButton} onClick={filterHandler}>
          Show Filters
        </Button>

        {showFilters && (
          <div>
            <Row>
              <Col>
                <Card className={classes.filterCard}>
                  <h4>Filter Dictionary</h4>

                  <div>
                    <Form.Label>{'Level:'}</Form.Label>
                    <Form.Control
                      as='select'
                      onChange={(e) =>
                        filterChangeHandler('level', e.target.value)
                      }
                    >
                      {dropdown_level_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Label>{'Level:'}</Form.Label>
                    <Form.Control
                      as='select'
                      onChange={(e) =>
                        filterChangeHandler('pos_', e.target.value)
                      }
                    >
                      {dropdown_pos_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                  <div>
                    <Col>
                      <SearchBox />
                    </Col>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        )}
        {/* {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>} */}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Card className={classes.tableCard}>
            <Table striped bordered hover responsive className='table-sm'>
              <thead>
                <tr>
                  <th>Text</th>
                  <th>Level</th>
                  <th>Definition</th>
                  <th>Sentence</th>
                  <th>Spanish</th>
                  <th>Sentence Translation</th>
                  <th>POS</th>
                  <th>TAG</th>
                  <th>LEMMA</th>
                  <th>Most Similar</th>
                  <th>Most Dissimilar</th>
                </tr>
              </thead>
              <tbody>
                {entries.map((entry) => (
                  <tr key={entry._id}>
                    <td>{entry.text}</td>
                    <td>{entry.level}</td>
                    <td>{entry.definition}</td>
                    <td>{entry.sentence}</td>
                    <td>{entry.word_spanish}</td>
                    <td>{entry.sentence_spanish}</td>
                    <td>{entry.pos_}</td>
                    <td>{entry.tag_}</td>
                    <td>{entry.lemma}</td>
                    <td>{entry.most_similar}</td>
                    <td>{entry.dissimilar}</td>
                    <td>
                      <LinkContainer to={`/admin/dictionary/${entry._id}/edit`}>
                        <Button variant='light' className='btn-sm'>
                          <i className='fas fa-edit'></i>
                        </Button>
                      </LinkContainer>
                      <Button
                        variant='danger'
                        className='btn-sm'
                        // onClick={() => deleteHandler(question._id)}
                      >
                        <i className='fas fa-trash'></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className={classes.paginationContainer}>
              <Paginate pages={pages} page={page} isAdmin={true} />
            </div>
          </Card>
        )}
      </div>
    </div>
  )
}

export default DictionaryListScreen
