import React from 'react'
import SEO from '../common/SEO'
import HeaderKorlo from '../common/header/HeaderKorlo'
import CopyrightKorlo from '../common/footer/CopyrightKorlo'
import Separator from '../elements/separator/Separator'
import SectionTitle from '../elements/sectionTitle/SectionTitle'
import AdvancePricingOne from '../elements/advancePricing/AdvancePricingOne'

const KorloBannerData = [
  {
    image: '/images/bg/bg-image-6.jpg',
    title: 'Korlo AI',
    description: 'Language learning for serious people',
  },
  {
    image: '/images/bg/bg-image-14.jpg',
    title: 'Korlo AI',
    description:
      'Next generation language learning that harnesses the power of AI and data analytics',
  },
  {
    image: '/images/bg/bg-image-15.jpg',
    title: 'Korlo AI',
    description: 'Gorgeous UX to ensure that the brain remains engaged',
  },
]

const KorloProductPage = () => {
  return (
    <>
      <SEO title='Korlo AI' />
      <main className='page-wrapper'>
        <HeaderKorlo btnStyle='btn-small' />

        <Separator />

        <div className='rwt-progressbar-area rn-section-gap'>
          <div className='container'>
            <div className='row mb--25'>
              <div className='col-lg-4 offset-lg-1'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Bringing AI to Language Learning'
                  title='A Personalized Learning Experience'
                  description='Our learning engine is designed for an AI future with students, teachers, and professionals in mind.'
                />
              </div>
              <div className='col-lg-4 offset-lg-1'></div>

              {/* <div className='col-lg-10 offset-lg-1'>
              <CircleProgress />
            </div> */}
            </div>
          </div>
        </div>

        <div className='rwt-progressbar-area rn-section-gap'>
          <div className='container'>
            <div className='row mb--25 mt-25'>
              <div className='col-lg-10 offset-lg-1'></div>
            </div>
            <AdvancePricingOne />

            {/* <div className='col-lg-10 offset-lg-1'>
              <CircleProgress />
            </div> */}
          </div>
        </div>
        {/* <Portfolio /> */}
        <CopyrightKorlo />
      </main>
    </>
  )
}
export default KorloProductPage
