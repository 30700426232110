import React from 'react'
import ContactForm from './ContactForm'
import GoogleMapStyle from './GoogleMapStyle'
import { FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi'

const ContactOne = () => {
  return (
    <>
      <div className='row row--15'>
        <div className='col-lg-12'>
          <div className='rn-contact-address mt_dec--30'>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-12'>
                <div className='rn-address'>
                  <div className='icon'>
                    <FiMail />
                  </div>
                  <div className='inner'>
                    <h4 className='title'>Email us!</h4>
                    <p>
                      <a href='mailto:admin@gmail.com'>info@korlo.ai</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-lg-6 col-md-6 col-12'>
                <div className='rn-address'>
                  <div className='icon'>
                    <FiMapPin />
                  </div>
                  <div className='inner'>
                    <h4 className='title'>HQ</h4>
                    <p>Ann Arbor, Michigan</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ContactOne
