import React from 'react'
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll'

const teamData = [
  {
    image: 'team-01',
    name: 'Sean C.',
    designation: 'Head of AI',
    location: 'Ann Arbor, Michigan',
    description: 'Head of AI',
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: '#',
      },
      {
        icon: <FiTwitter />,
        url: '#',
      },
      {
        icon: <FiInstagram />,
        url: '#',
      },
    ],
  },
  {
    image: 'team-03',
    name: 'Elisabetta D.',
    designation: 'Head of Policy',
    location: 'Rome, Italy',
    description: 'Head of Policy',
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: '#',
      },
      {
        icon: <FiTwitter />,
        url: '#',
      },
      {
        icon: <FiInstagram />,
        url: '#',
      },
    ],
  },
  // {
  //   image: 'team-02',
  //   name: 'Paola D.',
  //   designation: 'Head of Language',
  //   location: 'South Pacific',
  //   description: 'Head of Language',
  //   socialNetwork: [
  //     {
  //       icon: <FiFacebook />,
  //       url: '#',
  //     },
  //     {
  //       icon: <FiTwitter />,
  //       url: '#',
  //     },
  //     {
  //       icon: <FiLinkedin />,
  //       url: '#',
  //     },
  //   ],
  // },
  {
    image: 'team-03',
    name: 'Porscha K.',
    designation: 'Accounts Manager',
    location: 'Seattle, Washington',
    description: 'Head of People',
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: '#',
      },
      {
        icon: <FiTwitter />,
        url: '#',
      },
      {
        icon: <FiInstagram />,
        url: '#',
      },
    ],
  },
  {
    image: 'team-03',
    name: 'Jorge M.',
    designation: 'Accounts Manager',
    location: 'Santiago, Chile',
    description: 'Head of LATAM Operations',
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: '#',
      },
      {
        icon: <FiTwitter />,
        url: '#',
      },
      {
        icon: <FiInstagram />,
        url: '#',
      },
    ],
  },
  {
    image: 'team-03',
    name: 'Dawa P.',
    designation: 'Head of UX/Design',
    location: 'Paro, Bhutan',
    description: 'Head of UX/Design',
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: '#',
      },
      {
        icon: <FiTwitter />,
        url: '#',
      },
      {
        icon: <FiInstagram />,
        url: '#',
      },
    ],
  },
]

const KorloTeam = ({ column, teamStyle }) => {
  return (
    <div className='row row--15'>
      {teamData.map((data, index) => (
        <div className={`${column}`} key={index}>
          <div className={`rn-team ${teamStyle}`}>
            <ScrollAnimation
              animateIn='fadeInUp'
              animateOut='fadeInOut'
              animateOnce={true}
            >
              <div className='inner'>
                {/* <figure className='thumbnail'>
                  <img
                    src={`./images/team/${data.image}.jpg`}
                    alt='Corporate React Template'
                  />
                </figure> */}
                <figcaption className='content'>
                  <h2 className='title'>{data.name}</h2>
                  {/* <h6 className='subtitle theme-gradient'>
                    {data.designation}
                  </h6> */}
                  <span className='team-form'>
                    <img
                      src='./images/team/location.svg'
                      alt='Corporate React Template'
                    />
                    <span className='location'>{data.location}</span>
                  </span>
                  <p className='description'>{data.description}</p>

                  {/* <ul className='social-icon social-default icon-naked mt--20'>
                    {data.socialNetwork.map((social, index) => (
                      <li key={index}>
                        <a href={`${social.url}`}>{social.icon}</a>
                      </li>
                    ))}
                  </ul> */}
                </figcaption>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      ))}
    </div>
  )
}

export default KorloTeam
