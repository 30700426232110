import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AboutKorlo from './AboutKorlo.json'

const AccordionOne = ({ customStyle }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey='0'
    >
      {AboutKorlo.map((data, index) => (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant='link' eventKey={index + 1}>
              {data.title}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={index + 1}>
            <Card.Body>
              <div className='content'>
                {data.text.map((value, i) => {
                  return (
                    <div key={i} dangerouslySetInnerHTML={{ __html: value }} />
                  )
                })}

                {/* <div className='category-meta'>
                  <span className='text'>Tags:</span>
                  <SidebarTag />
                </div>

                <div className='rn-comment-form pt--60'>
                  <div className='comment-respond'>
                    <h4 className='title mb--40'>Leave a Reply</h4>
                    <Comment url='' id={data.id} title={data.title} />
                  </div>
                </div> */}
              </div>
              <div>
                {/* <Link className='btn-default btn-small' to={`/${data.link}`}>
                  Read More
                </Link> */}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  )
}
export default AccordionOne
