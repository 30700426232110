export const DICTIONARY_GET_ALL_REQUEST = 'DICTIONARY_GET_ALL_REQUEST'
export const DICTIONARY_GET_ALL_SUCCESS = 'DICTIONARY_GET_ALL_SUCCESS'
export const DICTIONARY_GET_ALL_FAIL = 'DICTIONARY_GET_ALL_FAIL'

export const GET_DICTIONARY_DETAILS_REQUEST = 'GET_DICTIONARY_DETAILS_REQUEST'
export const GET_DICTIONARY_DETAILS_SUCCESS = 'GET_DICTIONARY_DETAILS_SUCCESS'
export const GET_DICTIONARY_DETAILS_FAIL = 'GET_DICTIONARY_DETAILS_FAIL'

export const UPDATE_DICTIONARY_DETAILS_REQUEST =
  'UPDATE_DICTIONARY_DETAILS_REQUEST'
export const UPDATE_DICTIONARY_DETAILS_SUCCESS =
  'UPDATE_DICTIONARY_DETAILS_SUCCESS'
export const UPDATE_DICTIONARY_DETAILS_FAIL = 'UPDATE_DICTIONARY_DETAILS_FAIL'
export const UPDATE_DICTIONARY_DETAILS_RESET = 'UPDATE_DICTIONARY_DETAILS_RESET'
