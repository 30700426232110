import { Link } from "react-router-dom";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
// import SearchBox from './SearchBox'
import { logout } from "../../actions/userActions";

const NavKorlo = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <ul className="mainmenu">
      <li>
        <Link to="/about">About</Link>
      </li>

      {/* <li>
        <Link to='/product'>Product</Link>
      </li> */}
      {/* <li>
        <Link to="/team">Team</Link>
      </li> */}
      {/* <li>
        <Link to='/team'>Languages</Link>
      </li> */}
      <li>
        <Link to="/contact">Contact</Link>
      </li>
      {userInfo && (
        <li>
          <Link onClick={logoutHandler} to="/contact">
            Logout
          </Link>
        </li>
      )}
      {/* {userInfo.isAdmin && (
        <li>
          <Link to='/dictionary'>Dictionary</Link>
        </li>
      )} */}
      {/* <li className='with-megamenu'>
        <Link to='#'>Languages</Link>
        <div className='rn-megamenu'>
          <div className='wrapper'>
            <div className='row row--0'>
              <div className='col-lg-3 single-mega-item'>
                <ul className='mega-menu-item'>
                  <li>
                    <Link to='/english'>English</Link>
                  </li>
                  <li>
                    <Link to='/spanish'>Spanish</Link>
                  </li>
                  <li>
                    <Link to='/french'>French</Link>
                  </li>
                  <li>
                    <Link to='/portuguese'>Portuguese</Link>
                  </li>
                  <li>
                    <Link to='/italian'>Italian</Link>
                  </li>
                  <li>
                    <Link to='/German'>German</Link>
                  </li>
                  <li>
                    <Link to='/Dutch'>Dutch</Link>
                  </li>
                </ul>
              </div>
              <div className='col-lg-3 single-mega-item'>
                <ul className='mega-menu-item'>
                  <li>
                    <Link to='/team'>Japanese</Link>
                  </li>
                  <li>
                    <Link to='/portfolio'>Korean</Link>
                  </li>
                  <li>
                    <Link to='/testimonial'>Mandarin</Link>
                  </li>
                </ul>
              </div>
              <div className='col-lg-3 single-mega-item'>
                <ul className='mega-menu-item'>
                  <li>
                    <Link to='/call-to-action'>Call To Action</Link>
                  </li>
                  <li>
                    <Link to='/video-popup'>Video</Link>
                  </li>
                  <li>
                    <Link to='/gallery'>Gallery</Link>
                  </li>
                  <li>
                    <Link to='/contact'>Contact</Link>
                  </li>
                  <li>
                    <Link to='/brand'>Brand</Link>
                  </li>
                  <li>
                    <Link to='/portfolio'>Portfolio</Link>
                  </li>
                  <li>
                    <Link to='/error'>404</Link>
                  </li>
                </ul>
              </div>
              <div className='col-lg-3 single-mega-item'>
                <ul className='mega-menu-item'>
                  <li>
                    <Link to='/advance-tab'>
                      Advance Tab <span className='rn-badge-card'>Hot</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='#'>
                      Brand Carousel{' '}
                      <span className='rn-badge-card'>Comming</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='advance-pricing'>
                      Advance Pricing <span className='rn-badge-card'>Hot</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/portfolio-details/1'>Portfolio Details</Link>
                  </li>
                  <li>
                    <Link to='/blog-details/1'>Blog Details</Link>
                  </li>
                  <li>
                    <Link to='/error'>404 Page</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li> */}
    </ul>
  );
};
export default NavKorlo;
