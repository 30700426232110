import {
  DICTIONARY_GET_ALL_FAIL,
  DICTIONARY_GET_ALL_REQUEST,
  DICTIONARY_GET_ALL_SUCCESS,
  GET_DICTIONARY_DETAILS_FAIL,
  GET_DICTIONARY_DETAILS_REQUEST,
  GET_DICTIONARY_DETAILS_SUCCESS,
  UPDATE_DICTIONARY_DETAILS_FAIL,
  UPDATE_DICTIONARY_DETAILS_REQUEST,
  UPDATE_DICTIONARY_DETAILS_RESET,
  UPDATE_DICTIONARY_DETAILS_SUCCESS,
} from '../constants/dictionaryConstants'

export const dictionaryFilterReducer = (state = { entries: [] }, action) => {
  switch (action.type) {
    case DICTIONARY_GET_ALL_REQUEST:
      return { loading: true }
    case DICTIONARY_GET_ALL_SUCCESS:
      return {
        loading: false,
        entries: action.payload.entries,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case DICTIONARY_GET_ALL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dictionaryDetailsReducer = (state = { entry: {} }, action) => {
  switch (action.type) {
    case GET_DICTIONARY_DETAILS_REQUEST:
      return { ...state, loading: true }
    case GET_DICTIONARY_DETAILS_SUCCESS:
      return { loading: false, entry: action.payload }
    case GET_DICTIONARY_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dictionaryUpdateReducer = (state = { entry: {} }, action) => {
  switch (action.type) {
    case UPDATE_DICTIONARY_DETAILS_REQUEST:
      return { loading: true }
    case UPDATE_DICTIONARY_DETAILS_SUCCESS:
      return { loading: false, success: true, entry: action.payload }
    case UPDATE_DICTIONARY_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_DICTIONARY_DETAILS_RESET:
      return { entry: {} }
    default:
      return state
  }
}
