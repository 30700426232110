import { createStore, combineReducers, applyMiddleware } from 'redux'
import { thunk } from 'redux-thunk'
import {
  dictionaryDetailsReducer,
  dictionaryFilterReducer,
  dictionaryUpdateReducer,
} from './reducers/dictionaryReducers'
// import { composeWithDevTools } from 'redux-devtools-extension'
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userUpdateLevelReducer,
  userClearLevelReducer,
  userUpdateCustomLevelReducer,
} from './reducers/userReducers'

const reducer = combineReducers({
  dictionaryFilter: dictionaryFilterReducer,
  dictionaryDetails: dictionaryDetailsReducer,
  dictionaryUpdate: dictionaryUpdateReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userUpdateLevel: userUpdateLevelReducer,
  userUpdateCustomLevel: userUpdateCustomLevelReducer,
  userClearLevel: userClearLevelReducer,
})

// const cartItemsF

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const initializeParametersFromStorage = localStorage.getItem(
  'initializeParameters'
)
  ? JSON.parse(localStorage.getItem('initializeParameters'))
  : {}

const initializeCustomParametersFromStorage = localStorage.getItem(
  'initializeCustomParameters'
)
  ? JSON.parse(localStorage.getItem('initializeCustomParameters'))
  : {}

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  // answers: {
  //   answerData: answerDataFromStorage,
  // },

  initialize: { initializeParameters: initializeParametersFromStorage },
  initializeCustom: {
    initializeCustomParameters: initializeCustomParametersFromStorage,
  },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middleware)
  // composeWithDevTools(applyMiddleware(...middleware))
)

export default store
