import React from 'react'
import { Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Paginate = ({ pages, page, isAdmin = false, keyword = '' }) => {
  return (
    pages > 1 && (
      <Pagination>
        {page > 1 && (
          <LinkContainer
            key='first'
            to={
              !isAdmin
                ? keyword
                  ? `/admin/dictionary/search/${keyword}/page/${1}`
                  : `/page/${1}`
                : `/admin/dictionary/page/${1}`
            }
          >
            <Pagination.Item>First</Pagination.Item>
          </LinkContainer>
        )}
        {page > 1 && (
          <LinkContainer
            key='previous'
            to={
              !isAdmin
                ? keyword
                  ? `/admin/dictionary/search/${keyword}/page/${page - 1}`
                  : `/page/${page - 1}`
                : `/admin/dictionary/page/${page - 1}`
            }
          >
            <Pagination.Item>Previous</Pagination.Item>
          </LinkContainer>
        )}
        {page > 4 && <Pagination.Item>...</Pagination.Item>}
        {[...Array(pages).keys()]
          .filter((x) => x >= page - 4 && x <= page + 3)
          .map((x) => (
            <LinkContainer
              key={x + 1}
              to={
                !isAdmin
                  ? keyword
                    ? `/admin/dictionary/search/${keyword}/page/${x + 1}`
                    : `/page/${x + 1}`
                  : `/admin/dictionary/page/${x + 1}`
              }
            >
              <Pagination.Item active={x + 1 === page} activeLabel={''}>
                {x + 1}
              </Pagination.Item>
            </LinkContainer>
          ))}
        {page < pages - 5 && <Pagination.Item>...</Pagination.Item>}
        {page < pages && (
          <LinkContainer
            key='next'
            to={
              !isAdmin
                ? keyword
                  ? `/admin/dictionary/search/${keyword}/page/${page + 1}`
                  : `/page/${page + 1}`
                : `/admin/dictionary/page/${page + 1}`
            }
          >
            <Pagination.Item>Next</Pagination.Item>
          </LinkContainer>
        )}
        <LinkContainer
          key='last'
          to={
            !isAdmin
              ? keyword
                ? `/admin/dictionary/search/${keyword}/page/${pages}`
                : `/page/${1}`
              : `/admin/dictionary/page/${pages}`
          }
        >
          <Pagination.Item>Last ({pages})</Pagination.Item>
        </LinkContainer>
      </Pagination>
    )
  )
}

export default Paginate
