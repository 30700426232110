import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import HeaderKorlo from "../common/header/HeaderKorlo";
import CopyrightKorlo from "../common/footer/CopyrightKorlo";
import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import ServiceFive from "../elements/service/ServiceFive";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import CircleProgress from "../elements/progressbar/CircleProgress";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import AccordionOne from "../elements/accordion/AccordionOne";
import RnAccordion from "../elements/accordion/RnAccordion";
import AdvancePricing from "../elements/advancePricing/AdvancePricing";
import AdvancePricingOne from "../elements/advancePricing/AdvancePricingOne";
import BrandOne from "../elements/brand/BrandOne";
import BrandThree from "../elements/brand/BrandThree";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import GalleryOne from "../elements/gallery/GalleryOne";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Team from "../elements/team/Team";
import ContactOne from "../elements/contact/ContactOne";
var BlogListData = BlogClassicData.slice(0, 3);

const KorloBannerData = [
  {
    image: "/images/bg/bg-image-6.jpg",
    title: "Korlo AI",
    description: "Language learning for serious people",
  },
  {
    image: "/images/bg/bg-image-14.jpg",
    title: "Korlo AI",
    description:
      "Next generation language learning that harnesses the power of AI and data analytics",
  },
  {
    image: "/images/bg/bg-image-15.jpg",
    title: "Korlo AI",
    description: "Gorgeous UX to ensure that the brain remains engaged",
  },
];

const KorloBusinessPage = () => {
  return (
    <>
      <SEO title="Contact" />
      <main className="page-wrapper">
        <HeaderKorlo btnStyle="btn-small" />

        <Separator />

        <div className="rn-accordion-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1"></div>
            </div>
            <div className="row mt--35 row--20">
              <div className="col-lg-10 offset-lg-1">
                <ContactOne customStyle="" />
              </div>
            </div>
          </div>
        </div>
        <div className="rn-accordion-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1"></div>
            </div>
            <div className="row mt--35 row--20">
              <div className="col-lg-10 offset-lg-1"></div>
            </div>
          </div>
        </div>
        {/* End Accordion Area  */}
      </main>
    </>
  );
};
export default KorloBusinessPage;
